import React, { useEffect, useState } from "react";
import "./MainPageEn.css";
import logo1 from "../../Assets/logo.svg";
import logo2 from "../../Assets/life-logo.png";
import logo3 from "../../Assets/g20.png";
import recycle from "../../Assets/reduce-reuse.svg";
import glass from "../../Assets/glass.svg";
import eWaste from "../../Assets/e-waste.svg";
import metal from "../../Assets/metal.svg";
import paper from "../../Assets/paper.svg";
import plastic from "../../Assets/plastic.svg";
import organic from "../../Assets/org.svg";
import img1 from "../../Assets/img-1.png";
import img2 from "../../Assets/img-2.png";
import img3 from "../../Assets/img-4.png";
import img4 from "../../Assets/img-5.png";
import img5 from "../../Assets/img-6.png";
import img6 from "../../Assets/img-7.png";
import img7 from "../../Assets/img-8.png";
import img8 from "../../Assets/img-9.png";
import img9 from "../../Assets/img-10.png";
import back from "../../Assets/back.svg";
import { useNavigate } from "react-router-dom/dist";
export default function MainPageEn() {
  const navigate = useNavigate();
  const [selectedBin, setSelectedBin] = useState(null);
  const [selectedGarbage, setSelectedGarbage] = useState(null);
  const [match, setMatch] = useState(false);

  const handleBinClick = (e) => {
    if (!match) {
      setSelectedBin(e.target.id);
      e.target.style.opacity = 0.5;
    }
  };

  const handleGarbageClick = (e) => {
    if (!match) {
      setSelectedGarbage(e.target.id);
      e.target.style.opacity = 0.5;
    }
  };

  useEffect(() => {
    if (selectedBin !== null && selectedGarbage !== null) {
      if (selectedBin === selectedGarbage) {
        navigate(`eng-video/${selectedBin}`);
        setMatch(true);
      } else {
        console.log("Not Matched");
        setTimeout(() => {
          setSelectedBin(null);
          setSelectedGarbage(null);
          setMatch(false);
          navigate("eng-video/siren");

          document
            .querySelectorAll(".dustbin-image-eng, .garbage-image-eng")
            .forEach((element) => {
              element.style.opacity = 1;
            });
        }, 500);
      }
    }
  }, [selectedBin, selectedGarbage, navigate]);

  return (
    <div className="main-container-eng">
      <div className="logo-container-eng">
        <div className="logo-image-contaier-eng">
          <img src={logo1} alt="Logo-1" className="logo-image-eng-1" />
        </div>
        <div className="logo-image-contaier-eng">
          <img src={logo2} alt="Logo-2" className="logo-image-eng" />
        </div>
        <div className="logo-image-contaier-eng">
          <img src={logo3} alt="Logo-3" className="logo-image-eng" />
        </div>
      </div>
      <div className="main-heading-eng">
        <img src={recycle} alt="" className="main-heading-image-eng" />
      </div>
      <div className="dustbins-container-eng">
        <div className="dustbin-container-1">
          <div className="dustbins-image-container-eng">
            <img
              src={glass}
              alt="Glass"
              className="dustbin-image-eng"
              id="1"
              onClick={handleBinClick}
            />
          </div>
          <div className="dustbins-image-container-eng">
            <img
              src={eWaste}
              alt="E-Waste"
              className="dustbin-image-eng"
              id="2"
              onClick={handleBinClick}
            />
          </div>
          <div className="dustbins-image-container-eng">
            <img
              src={metal}
              alt="Metal"
              className="dustbin-image-eng"
              id="3"
              onClick={handleBinClick}
            />
          </div>
        </div>
        <div className="dustbin-container-2">
          <div className="dustbins-image-container-eng">
            <img
              src={paper}
              alt="Paper"
              className="dustbin-image-eng"
              id="4"
              onClick={handleBinClick}
            />
          </div>
          <div className="dustbins-image-container-eng">
            <img
              src={plastic}
              alt="Plastic"
              className="dustbin-image-eng"
              id="5"
              onClick={handleBinClick}
            />
          </div>
          <div className="dustbins-image-container-eng">
            <img
              src={organic}
              alt="Organic"
              className="dustbin-image-eng"
              id="6"
              onClick={handleBinClick}
            />
          </div>
        </div>
      </div>

      <div className="garbage-container-eng">
        <div className="garbage-container-eng-1">
          <div className="garbage-image-container-eng">
            <img
              src={img1}
              alt="Organic"
              className="garbage-image-eng"
              id="5"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img2}
              alt="Organic"
              className="garbage-image-eng"
              id="1"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img3}
              alt="Organic"
              className="garbage-image-eng"
              id="2"
              onClick={handleGarbageClick}
            />
          </div>
        </div>
        <div className="garbage-container-eng-2">
          <div className="garbage-image-container-eng">
            <img
              src={img4}
              alt="Organic"
              className="garbage-image-eng"
              id="4"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img5}
              alt="Organic"
              className="garbage-image-eng"
              id="1"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img6}
              alt="Organic"
              className="garbage-image-eng"
              id="6"
              onClick={handleGarbageClick}
            />
          </div>
        </div>
        <div className="garbage-container-eng-3">
          <div className="garbage-image-container-eng">
            <img
              src={img7}
              alt="Organic"
              className="garbage-image-eng"
              id="6"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img8}
              alt="Organic"
              className="garbage-image-eng"
              id="2"
              onClick={handleGarbageClick}
            />
          </div>
          <div className="garbage-image-container-eng">
            <img
              src={img9}
              alt="Organic"
              className="garbage-image-eng"
              id="3"
              onClick={handleGarbageClick}
            />
          </div>
        </div>
      </div>
      <div>
        <img
          src={back}
          alt="Back"
          className="back-image-eng"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
