// Package Imports
import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
// Component Imports

// Route Imports
import HomeRoute from "./Routes/HomeRoute";

const App = () => {
  return (
    <Router>
      <Fragment>
        <HomeRoute />
      </Fragment>
    </Router>
  );
};

export default App;
