import { SET_ALERT, REMOVE_ALERT } from '../Actions/Types';

const initialState = [];

export const alertState = (state = initialState, action) => {
    // Destructure Request
    const { type, payload } = action;

    switch (type) {
        case SET_ALERT: {
            return [...state, action.payload];
        }

        case REMOVE_ALERT: {
            return state.filter((alert) => {
                return alert.id !== payload;
            });
        }

        default: {
            return state;
        }
    }
};
