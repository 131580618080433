// Package Imports
import React from "react";
import { Routes, Route } from "react-router-dom";

// View Imports
// import CricularView from "../Views/Home/CricularView";
import HomeView from "../Views/Home/HomeView";
import MainPageEn from "../Views/Home/MainPageEn";
import HomePageHin from "../Views/Home/MainPageHin";
import VideoView from "../Views/Home/VideoView";

//video Imports

import eng1 from "../Assets/english/Glass Dustbin English.mp4";
import eng2 from "../Assets/english/E- Waste Dustbin English.mp4";
import eng3 from "../Assets/english/Metal Dustbin English.mp4";
import eng4 from "../Assets/english/Paper Dustbin English.mp4";
import eng5 from "../Assets/english/Plastic Dustbin English.mp4";
import eng6 from "../Assets/english/Organic Dustbin English.mp4";
import sirenEng from "../Assets/english/Siren English.mp4";

import hin1 from "../Assets/hindi/Glass Dustbin Hindi.mp4";
import hin2 from "../Assets/hindi/E- Waste Dustbin Hindi.mp4";
import hin3 from "../Assets/hindi/Metal Dustbin Hindi.mp4";
import hin4 from "../Assets/hindi/Paper Dustbin Hindi.mp4";
import hin5 from "../Assets/hindi/Plastic Dustbin Hindi.mp4";
import hin6 from "../Assets/hindi/Organic Dustbin Hindi.mp4";
import sirenHindi from "../Assets/hindi/siren Hindi.mp4";

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/eng" element={<MainPageEn />} />
      <Route path="/hin" element={<HomePageHin />} />
      <Route
        path="eng/eng-video/1"
        element={<VideoView video={eng1} type="eng" />}
      />
      <Route
        path="eng/eng-video/2"
        element={<VideoView video={eng2} type="eng" />}
      />
      <Route
        path="eng/eng-video/3"
        element={<VideoView video={eng3} type="eng" />}
      />
      <Route
        path="eng/eng-video/4"
        element={<VideoView video={eng4} type="eng" />}
      />
      <Route
        path="eng/eng-video/5"
        element={<VideoView video={eng5} type="eng" />}
      />
      <Route
        path="eng/eng-video/6"
        element={<VideoView video={eng6} type="eng" />}
      />
      <Route
        path="eng/eng-video/siren"
        element={<VideoView video={sirenEng} type="eng" />}
      />
      <Route
        path="hin/hin-video/1"
        element={<VideoView video={hin1} type="hin" />}
      />
      <Route
        path="hin/hin-video/2"
        element={<VideoView video={hin2} type="hin" />}
      />
      <Route
        path="hin/hin-video/3"
        element={<VideoView video={hin3} type="hin" />}
      />
      <Route
        path="hin/hin-video/4"
        element={<VideoView video={hin4} type="hin" />}
      />
      <Route
        path="hin/hin-video/5"
        element={<VideoView video={hin5} type="hin" />}
      />
      <Route
        path="hin/hin-video/6"
        element={<VideoView video={hin6} type="hin" />}
      />
      <Route
        path="hin/hin-video/siren"
        element={<VideoView video={sirenHindi} type="hin" />}
      />
    </Routes>
  );
};

export default HomeRoute;
