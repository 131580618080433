// Package Imports
import { combineReducers } from 'redux';

// Reducer Imports
import { alertState } from './alertReducer';
import { authState } from './AuthReducer';

export default combineReducers({ alertState, authState });


