// Package Imports
import React from "react";
import "./VideoView.css";
import { useNavigate } from "react-router-dom";
const VideoView = ({ video, type }) => {
  const navigate = useNavigate();
  const handleEnded = () => {
    navigate(`/${type}`);
  };
  return (
    <>
      <div className="fullscreen-video-container">
        <div className="close-btn-container">
          <button className="close-button" onClick={handleEnded}>
            X
          </button>
        </div>
        <video
          autoPlay
          controls
          className="video-background"
          onEnded={handleEnded}
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default VideoView;
