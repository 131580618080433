// Package Imports
import React from "react";
import "./HomeView.css";
import kid from "../../Assets/mqt.png";
import logo from "../../Assets/logo.png";
import buttonEnglish from "../../Assets/eng.png";
import buttonhindi from "../../Assets/hindi.png";
import { useNavigate } from "react-router-dom";
const HomeView = () => {
  const navigate = useNavigate();
  return (
    <div className="main-container">
      <div className="kid-container">
        <img src={kid} alt="kid" className="kid-image" />
      </div>
      <div className="logo-button-container">
        <div className="logo-container">
          <img src={logo} alt="logo" className="logo-image" />
        </div>
        <div className="button-container">
          <img
            src={buttonEnglish}
            alt="btn-eng"
            className="button-image"
            onClick={() => navigate("/eng")}
          />
          <img
            src={buttonhindi}
            alt="btn-hin"
            className="button-image"
            onClick={() => navigate("/hin")}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeView;
